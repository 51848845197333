<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>LISTA DE CICLOS DE EVALUACION</b></h4>
          </template>
          <div class="text-right px-4 mt-3">
            <button class="mx-1 btn btn-success" @click="$router.push('Ciclo_evaluaciones_agregar')"><i class="fas fa-plus"></i>Agregar ciclo</button>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_evaluaciones" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Titulo ciclo</th>
                  <th>Empresa</th>
                  <th>Fecha desde</th>
                  <th>Fecha hasta</th>
                  <th>Detalles</th>

                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.empresa.nombre }}</td>
                  <td>
                    {{ $moment.tz(item.fechaDesde, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}
                  </td>
                  <td>
                    {{ $moment.tz(item.fechaHasta, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}
                  </td>

                  <td><b-button @click="showModal(item)">Ver más</b-button></td>
                  <td>
                    <div class="row px-2">
                      <!-- <div class="col px-0 mx-0">
                        <i class="zoom fas fa-solid fa-file px-1 text-primary"></i
                      ></div>
                      <div class="col px-0 mx-0" @click="$router.push('Persona_historial_laboral')">
                        <i class="zoom fas fa-solid fa-clock px-1 text-warning"></i>
                      </div> -->
                      <div class="col px-0 mx-0 mt-2" @click="$router.push('Ciclo_evaluaciones_editar')">
                        <i class="zoom fas fa-solid fa-pen px-1 text-success"></i>
                      </div>
                      <download-excel
                        class="btn"
                        :fetch="
                          () => {
                            return obtenerUsuarios(item);
                          }
                        "
                        :fields="json_fields"
                        worksheet="My Worksheet"
                        name="evaluaciónUsuarios.xls"
                        ><i class="zoom fas fa-file-excel fa-1x text-blue"></i>
                      </download-excel>

                      <div class="col px-0 mx-0 mt-2 mr-2 ml-0" @click="verificarCiclo(item)">
                        <i class="zoom fas fa-solid fa-eye px-0 text-warning"></i>
                      </div>
                      <div class="col px-0 mx-0 mt-2 mr-2" @click="eliminarCiclo(item)">
                        <i class="zoom fas fa-solid fa-trash px-1 text-danger"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <b-modal id="my-modal" size="lg" title="Modal title" ok-title="Save Changes" cancel-title="Close">
              <p>Modal body text goes here.</p>
            </b-modal> -->
          <b-modal id="my-modal" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="md" ok-only title="Información del ciclo" ok-title="Cerrar">
            <div class="text-center">
              <h3 class="text-white">Información General</h3>
              <div class="text-center">
                <div class="row">
                  <div class="col-12 text-left">
                    <span> Título: </span>
                    <a class="mt-3 bold">
                      {{ rowModal.nombre }}
                    </a>
                  </div>
                  <div class="col-12 text-left mt-3">
                    <span> Empresa: </span>
                    <span class="mt-3">
                      {{ rowModal.empresa.nombre }}
                    </span>
                  </div>
                </div>
              </div>
              <h3 class="text-white mt-4">Descripción</h3>
              <div class="text-center">
                <div class="row">
                  <div class="col-12 text-left">
                    <a class="mt-3">
                      {{ rowModal.descripcion }}
                    </a>
                  </div>
                </div>
              </div>

              <h3 class="text-white mt-4">Fechas</h3>
              <div class="text-center">
                <div class="row">
                  <div class="col-12 text-left mt-2">
                    <span> Fecha de ingreso: </span>

                    <a class="mt-3">
                      {{ $moment.tz(rowModal.fechaIngreso, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}
                    </a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 text-left mt-3">
                    <span> Fecha de inicio: </span>

                    <a class="mt-3">
                      {{ $moment.tz(rowModal.fechaDesde, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}
                    </a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 text-left mt-3">
                    <span> Fecha de inicio: </span>

                    <a class="mt-3">
                      {{ $moment.tz(rowModal.fechaHasta, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}
                    </a>
                  </div>
                </div>
              </div>
              <h3 class="text-white mt-4">Ponderados</h3>
              <div class="text-center">
                <div class="row">
                  <div class="col-12 text-left mt-2">
                    <span>Conductuales:</span>
                    <a class="mt-3">
                      {{ rowModal.ponderaConductuales }}
                    </a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 text-left mt-3">
                    <span>Funcionales:</span>

                    <a class="mt-3">
                      {{ rowModal.ponderaFuncionales }}
                    </a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 text-left mt-3">
                    <span>Metas:</span>

                    <a class="mt-3">
                      {{ rowModal.ponderaMetas }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
          <b-modal id="my-modal-errores" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="lg" ok-only title="Errores encontrados en ciclo de evaluación:" ok-title="Cerrar">
            <div class="text-center">
              <h3 class="text-white"></h3>
              <div class="mt-3">
                <table class="table ">
                  <thead class="">
                    <tr class="">
                      <th class="" scope="col">CicloEvaluacionUsuarioId</th>
                      <th class="" scope="col">Nombre Evaluado</th>
                      <th class="" scope="col">Detalle</th>
                      <th class="" scope="col">Tipo Ev.</th>
                    </tr>
                  </thead>
                  <tbody class="border" v-for="(itemTable,i) of usuariosProblema" :key="i">
                    <template>
                      <tr>
                        <td class="border">{{ itemTable.cicloEvaluacionUsuario }}</td>
                        <td class="border">{{ itemTable.persona }}</td>
                        <td class="border">{{ itemTable.detalle }}</td>
                        <td class="border">{{ itemTable.tipoEv==0 ? 'AutoEvaluacion' :itemTable.tipoEv==1 ? 'Jefe' : 'Par' }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");

import JsonExcel from "vue-json-excel";
import Vue from "vue";
Vue.component("downloadExcel", JsonExcel);

export default {
  name: "DataTable",
  data() {
    return {
      tableName: "Persona",
      listaItems: [],
      persona: {},
      empresaId: "",
      listaPersonaUbicacion: [],
      rowModal: { nombre: "", titulo: "", empresa: { nombre: "" } },
      json_fields: {
        EstadoEvaluacion: "estadoEvaluacion",
        FechaDesde: "fechaDesde",
        FechaHasta: "fechaHasta",
        evaluador: "evaluador",
        CorreoEvaluador: "correoEvaluador",
        Evaluado: "evaluado",
        // correonotificaciones: "correonotificaciones",
        // proveedor: "nombreproveedor",
      },
      listaControlEvaluaciones: [],
      cicloEvaluacionId: null,
      // para evaluacion de ciclo
      CicloEvaluacionUsuario: [],
      usuariosProblema:[]
    };
  },
  async mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.empresaId = this.persona.empresa.id;
    await this.getData();
    await this.getPersonaUbicacion();
    core.index();
    window.$("#datatable_evaluaciones").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
  },
  methods: {
    async getPersona(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", { path: "Persona/GetPersona/" + personaId });
        return res;
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    async getCicloEvaluacionUsuarioLista(cicloId) {
      console.log("get cicloev....", cicloId);
      try {
        let res = await this.$store.dispatch("hl_get", { path: "CicloEvaluacionUsuario/GetUsuariosByCicloEvaluacionId/" + cicloId });
        this.CicloEvaluacionUsuario = res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async verificarCiclo(item) {
      try {
        this.$isLoading(true);
        console.log("item", item);
        this.usuariosProblema = [];
        //obtener lista de usuarios perprincipal del ciclo seleccionado
        await this.getCicloEvaluacionUsuarioLista(item.id);
        for await (let cicloEvaluacionUsuario of this.CicloEvaluacionUsuario) {
          if (item.tipoCompetencia == 0) {
            console.log("tipo competencia == 0", cicloEvaluacionUsuario.perPrincipal);
            let persona = await this.getPersona(cicloEvaluacionUsuario.perPrincipal);
            let ubicacion = await this.$store.dispatch("hl_get", { path: "PersonaUbicacion/GetByPersona/" + cicloEvaluacionUsuario.perPrincipal });
            let CargoCompt = await this.$store.dispatch("hl_get", { path: "CargoCompetencia/ListByCargo/" + ubicacion.cargo.id });
            let CicloCompt = await this.$store.dispatch("hl_get", { path: "CicloEvaluacionCompetencia/ListCompetenciaByCiclo/" + item.id });
            console.log("--->ca1", CargoCompt);
            console.log("--->ca2", CicloCompt);
            if (CicloCompt.length == 0) {
              this.usuariosProblema.push({
                cicloEvaluacionUsuario: cicloEvaluacionUsuario.id,
                persona: persona.nombres + " " + persona.apellidos,
                detalle: "Usuario evaluado no posee competencias en ciclo, evaluación sin preguntas.",
                tipoEv:cicloEvaluacionUsuario.rolEvaluacion
              });
            }
            if (CargoCompt.length == 0) {
              this.usuariosProblema.push({
                cicloEvaluacionUsuario: cicloEvaluacionUsuario.id,
                persona: persona.nombres + " " + persona.apellidos,
                detalle: "Cargo de usuario evaluado sin competencias.",
                tipoEv:cicloEvaluacionUsuario.rolEvaluacion
              });
            }
            console.log(this.usuariosProblema);
          } else {
            console.log("tipo competencia > 0", item.id);
            let res = await this.$store.dispatch("hl_get", { path: "CicloEvaluacionCompetencia/ListCompetenciaByCiclo/" + item.id });
            console.log(res);
          }
        }
        this.$isLoading(false);

        if (this.usuariosProblema.length == 0) {
          Swal.fire("Listo!", "Ciclo no posee errores!.", "success");
        } else {
          this.$bvModal.show("my-modal-errores");
        }
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },

    async eliminarCiclo(item) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          // await this.$store.dispatch("hl_get", {
          //   path: "Empresa/delete/" + id,
          // });
          let res = await this.$store.dispatch("hl_delete", {
            path: "CicloEvaluacion/EliminarCicloEvaluacion/" + item.id,
          });
          console.log("res eliminación ", res);
          //loader.hide()
          setTimeout(async () => {
            await this.getData();
            this.$forceUpdate();
          }, 2000);

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });

      // console.log(item);
      // console.log(res);
    },
    async obtenerUsuarios(item) {
      console.log(item);
      let res = await this.$store.dispatch("hl_get", {
        path: "ControlEvaluacion/GetControlEvaluacion/" + item.id,
      });
      console.log("post empresas...", res);
      if (res.length > 0) {
        for await (let it of res) {
          it.estadoEvaluacion = it.estadoEvaluacion == 1 ? "Pendiente" : "Finalizada";
          it.fechaDesde = this.$moment.tz(it.fechaDesde, "America/Bogota").format("YYYY-MM-DD HH:mm");
          it.fechaHasta = this.$moment.tz(it.fechaHasta, "America/Bogota").format("YYYY-MM-DD HH:mm");
        }
      } else {
        Swal.fire("Advertencia:", "Ciclo de evaluación sin usuarios.", "warning");
      }
      return res;
    },
    showModal(item) {
      this.rowModal = item;
      this.$bvModal.show("my-modal");
    },
    obtenerUbicacionPersona(item) {
      let io = this.listaPersonaUbicacion.find((x) => x.persona.id == item.id);
      if (io > 0) {
        let result = this.listaPersonaUbicacion.indexOf(io);
        console.log("cargo ", result);
      }
      return io ? io.cargo.nombre : "";
    },
    async getPersonaUbicacion() {
      try {
        //   console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/List/" + this.empresaId,
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaPersonaUbicacion = res;
        } else {
          this.listaPersonaUbicacion = [];
        }
      } catch (error) {
        this.listaPersonaUbicacion = [];
        console.log("err", error);
      }
    },
    async getData() {
      console.log(this.persona.empresa.id);
      try {
        console.log("get ciclo ev...");
        let res = await this.$store.dispatch("hl_get", {
          path: "CicloEvaluacion/ListCicloEvaluacion/" + this.empresaId,
        });
        console.log("...", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style scoped>
.bold {
  font-weight: bold !important;
}
</style>
